import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import Image from 'gatsby-image'

import { useSiteMetadata } from '../../utils/hooks'
import { Container } from '../../utils/styles'
import { Wrapper, Links, Item, SocialMediaLinks } from './styles'
import { Instagram, Facebook, Pinterest } from './icons'

const Footer = ({ color = 'black' }) => {
  const { title, secondaryNav, socialLinks } = useSiteMetadata()
  const { illustration } = useStaticQuery(graphql`
    query {
      illustration: file(relativePath: { eq: "Illustration.png" }) {
        childImageSharp {
          fluid(maxWidth: 600) {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
          }
        }
      }
    }
  `)

  const socialMediaIcons = (name, color) => {
    switch (name.toLowerCase()) {
      case 'instagram':
        return <Instagram color={color} height="18px" />

      case 'facebook':
        return <Facebook color={color} height="18px" />

      case 'pinterest':
        return <Pinterest color={color} height="18px" />

      default:
        break
    }
  }

  return (
    <Container>
      <Wrapper>
        <Links>
          {secondaryNav.map(({ name, link }) => (
            <Item to={link} key={name} color={color}>
              {name}
            </Item>
          ))}
        </Links>
        <Links>
          <SocialMediaLinks color={color}>
            {socialLinks.map(({ name, link }) => {
              return (
                <li key={name}>
                  {socialMediaIcons(name, color)}
                  <a
                    href={link}
                    key={name}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {name}
                  </a>
                </li>
              )
            })}
          </SocialMediaLinks>
        </Links>
        <Links style={{ color: color }}>
          © {new Date().getFullYear()} {title}
          <br />
          <br />
          Konzept & Entwicklung:
          <a style={{ color: color }} href="https://alexanderhoerl.de">
            Alexander Hörl
          </a>
        </Links>
        <Image fluid={illustration.childImageSharp.fluid} alt="Illustration" />
      </Wrapper>
    </Container>
  )
}

export default Footer
