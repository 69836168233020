import { useState, useEffect } from 'react'
import { useStaticQuery, graphql } from 'gatsby'

export const useDate = () => {
  const [date, setDate] = useState(new Date()) // Save the current date to be able to trigger an update

  useEffect(() => {
    const timer = setInterval(() => {
      // Creates an interval which will update the current data every minute
      // This will trigger a rerender every component that uses the useDate hook.
      setDate(new Date())
    }, 60 * 1000)
    return () => {
      clearInterval(timer) // Return a funtion to clear the timer so that it will stop being called on unmount
    }
  }, [])

  return date
}

export const useWindowDimensions = () => {
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  )

  function getWindowDimensions() {
    if (typeof window !== 'undefined') {
      const { innerWidth: width, innerHeight: height } = window
      return {
        width,
        height,
      }
    }
    // fallback for headless build process
    return {
      width: 1920,
      height: 1080,
    }
  }

  useEffect(() => {
    const handleResize = () => {
      setWindowDimensions(getWindowDimensions())
    }

    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  return windowDimensions
}

export const useFetch = url => {
  const [error, setError] = useState()
  const [loading, setLoading] = useState(false)
  const [response, setResponse] = useState()

  useEffect(() => {
    const fetchInfo = async () => {
      setLoading(true)
      try {
        const response = await fetch(url)
        const result = await response.json()
        setResponse(result)
      } catch (error) {
        setError(error)
      }
      setLoading(false)
    }

    fetchInfo()
  }, [url])

  return { error, loading, response }
}

export const useWeather = () => {
  const [error, setError] = useState()
  const [loading, setLoading] = useState(false)
  const [response, setResponse] = useState()

  useEffect(() => {
    const fetchInfo = async () => {
      setLoading(true)
      try {
        const res = await fetch(
          'https://api.openweathermap.org/data/2.5/onecall?lat=49.455143&lon=11.072194&exclude=minutely,hourly,daily,alerts&appid=f974acfa1a45c090a403ddbf4474c825'
        )
        const result = await res.json()
        setResponse(result)
      } catch (error) {
        setError(error)
      }
      setLoading(false)
    }

    const timer = setInterval(fetchInfo(), 2 * 60 * 60 * 1000)
    return () => {
      clearInterval(timer)
    }
  }, [])

  return { error, loading, response }
}

export const useSiteMetadata = () => {
  const { site } = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          title
          description
          author
          keywords
          socialLinks {
            name
            link
          }
          secondaryNav {
            name
            link
          }
        }
      }
    }
  `)
  return site.siteMetadata
}
